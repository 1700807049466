<template>
  <s-drawer
    v-model:visible="visible"
    class="report__container"
    direction="rtl"
    type="full"
    close-icon="suiiconfont sui_icon_nav_back_24px"
    @closed="closeHandle"
  >
    <template #title>
      <h4
        ref="title"
        tabindex="0"
        class="nav-title"
      >
        {{ language.SHEIN_KEY_PWA_20498 }}
      </h4>
    </template>
    <div class="content">
      <h3 class="title">
        {{ language.SHEIN_KEY_PWA_20499 }}
      </h3>
      <s-radio-group
        v-model="selectValue"
        class="select-list"
      >
        <div
          v-for="(item, index) in options"
          :key="index"
          class="select-list__item"
        >
          <s-radio
            :label="index+1"
            :toggle="true"
          >
            {{ item }}
          </s-radio>
        </div>
      </s-radio-group>

      <s-button
        class="btn"
        :type="['primary', 'H44PX']"
        :disabled="!selectValue"
        width="100%"
        @click="submit"
      >
        {{ language.SHEIN_KEY_PWA_20497 }}
      </s-button>
    </div>
  </s-drawer>
</template>

<script>
import { defineComponent } from 'vue'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SRadioGroup } from '@shein-aidc/sui-radio-group/mobile'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
const defaultConfig = {
  commentId: '',
  skc: '',
  isFree: false,
  callbacks: {
    submit: () => {}
  }
}
export default defineComponent({
  name: 'ReportContainer',
  components: {
    SButton,
    SRadioGroup,
    SRadio,
    SDrawer,
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      config: {},
      visible: false,
      selectValue: ''
    }
  },
  methods: {
    submit() {
      const { selectValue } = this
      this.reportHandle(selectValue)
    },
    closeHandle() {
      this.selectValue = ''
      if (location.hash.indexOf('#comment-report') > -1) {
        history.go(-1)
      }
    },
    // 举报回调
    async reportHandle(reason) {
      const { comment_id, skc, isFree, scene } = this.config
      let url = ''

      let res = {}
      const params = {
        reason_id: reason,
        reportType: '1',
        scene,
      }
      if (isFree) {
        params.report_id = comment_id
        params.goods_sn = skc
        url = '/user/trial/report_tip_off'
      }else {
        params.commit_id = comment_id
        url = '/product/comment/comment_tip_off'
      }
      res = await schttp({
        method: 'POST',
        url,
        params,
        useBffApi: true,
      })


      const message = this.getMessage(+res.code)
      SToast(message)
      this.visible = false
      // CallBack
      this.config.callbacks.submit({ comment_id, reason })
    },
    // 获取反馈信息
    getMessage(code) {
      switch (code) {
        case 0:
          return this.language.SHEIN_KEY_PWA_20500
        case 402028:
        case 200745:
          return this.language.SHEIN_KEY_PWA_20501
        case 200746:
        case 402029:
          return this.language.SHEIN_KEY_PWA_21414
        default:
          return this.language.SHEIN_KEY_PWA_21338
      }
    },
    open(config) {
      if (!config.comment_id) {
        console.error('缺少商品评论 comment_id!')
        return
      }
      this.config = Object.assign({}, defaultConfig, config)

      if (!isLogin()) {
        // not login
        this.showLogin({
          cb: () => (this.visible = true)
        })
        return
      }

      this.visible = true
    },
    closeReport() {
      this.visible = false
    },
    showLogin({ cb }) {
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        reload: true,
        cb: () => {
          cb && cb()
          if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
            appEventCenter.emit('detailLoginSucCb')
            this.config.callbacks?.loginedHandle?.()
          }
        }
      })
    }
  },
})
</script>

<style lang="less">
.report__container {
  .suiiconfont,
  .nav-title {
    font-weight: 700;
    font-size: 0.4533rem;
  }
  .content {
    padding: 0 0.4267rem;
    .title {
      padding: 0.8rem 0;
      color: #222;
      font-weight: 700;
      font-size: 0.427rem;
      line-height: 0.507rem;
      text-align: center;
    }
  }
  .select-list__item {
    display: inline-flex;
    height: 1.28rem;
    width: 100%;
    padding: 0 0.3733rem;
    font-weight: 400;
    font-size: 0.3733rem;
    align-items: center;
    background: #f6f6f6;
    margin-bottom: 0.0533rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-top: 1.067rem;
  }
}
</style>
