import { createApp } from 'vue'
import Report from './report.vue'
import ReportDsa from './reportDsa.vue'
let ReportInstance = null
let DsaReportInstance = null
const CommentReportPopover = {
  open: ({ config, props }) => {
    const isDsaSite = ['meur', 'meuqs', 'pwfr', 'pwde', 'pwit', 'mes', 'pwnl', 'mpl', 'mpt', 'mroe', 'pwes', 'mse', 'pwuk', 'mat', 'mro'].includes(gbCommonInfo?.SiteUID)
    const isDsaReport = isDsaSite && !config?.isFree && gbCommonInfo?.WEB_CLIENT === 'shein'
    if (ReportInstance && !isDsaReport) return ReportInstance.open(config)
    if (DsaReportInstance && isDsaReport) return DsaReportInstance.open(config)
    const appDom = document.createElement('div') 
    const app = createApp(isDsaReport ? ReportDsa : Report, props)
    const vm = app.mount(appDom)
    document.body.appendChild(vm.$el)
    const Instance = vm
    Instance.open(config)
    if (isDsaReport) {
      DsaReportInstance = Instance
    } else {
      ReportInstance = Instance
    }
  },
  close: () => {
    if (ReportInstance) return ReportInstance.closeReport()
    if (DsaReportInstance) return DsaReportInstance.closeReport()
  }
}

export default CommentReportPopover
